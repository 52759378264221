<template>
  <div class="headers flex-align-between">
    <div class="left-nav flex-align">
      <div class="logo">
        <h1 class="skyviewor">
          <a href="/" rel="noopener noreferrer" class="logo-bd flex-align">
            <img src="@/assets/images/headers/logo.png" alt="观天者" />
          </a>
        </h1>
      </div>
      <ul class="nav-list flex-align">
        <li class="nav csp" v-for="(item,index) in navList" :key="index">
          <a :href="item.href">{{item.name}}</a></li>
      </ul>
    </div>
    <div class="right-login flex-align">
      <el-input class="input-search" placeholder="搜索" prefix-icon="el-icon-search" v-model="search">
      </el-input>
      <div class="line"></div>
      <a class="login csp">登录</a>
      <a class="register csp">免费注册</a>
    </div>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        navList: [{
            name: "社区",
            herf: "javascript:;"
          },
          {
            name: "商城",
            herf: "javascript:;"
          },
          {
            name: "帮助",
            herf: "javascript:;"
          },
          {
            name: "了解观天者",
            herf: "javascript:;"
          }
        ],
        search: "",
      }
    },
    methods: {},
    mounted() {},
    activated() {}
  };
</script>

<style lang="less" scoped>
  // @import "./less/headers.less";

  .headers {
    // height: 74px;
    background-color: #fff;
    backdrop-filter: blur(10px);
    padding: 0 120px;
    border-bottom: 1px solid #D9D9D9;

    .logo-bd {
      padding: 0 17px;

      img {
        width: 101px;
      }
    }

    .left-nav,
    .nav-list,
    .right-login {
      flex-shrink: 0;
    }



    .nav {
      font-size: 14px;
      padding: 0 10px;
      line-height: 72px;
      text-decoration: none;
      transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
      font-weight: normal;

      a {
        color: #666;
      }

      &:hover {
        text-decoration: underline;


        // font-weight: bold;
        a {
          color: #333;
        }
      }
    }

    .input-search {
      width: 320px;

      /deep/ .el-input__prefix {
        left: 12px;
      }

      /deep/ .el-input__inner {
        border-radius: 8px;
        // color: #d9d9d9;
        padding-left: 40px;
      }

      /deep/ .el-icon-search:before {
        font-size: 14px;
        color: #666;
      }
    }




    .right-login {
      .line {
        margin-left: 20px;
        margin-right: 4px;
        width: 1px;
        height: 16px;
        background-color: #d9d9d9;

      }

      .login {
        font-size: 14px;
        padding: 0 8px;
        line-height: 72px;
        text-decoration: none;
        transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
        font-weight: normal;

        a {
          color: #666;
        }

        &:hover {
          text-decoration: underline;

          // font-weight: bold;
          a {
            color: #333;
          }

        }
      }

      .register {
        margin-left: 16px;
        border-radius: 6px;
        padding: 0 18px;
        background-color: #3E6EFF;
        line-height: 40px;
        font-size: 14px;
        font-weight: 600;
        color: #fff;
        user-select: none;
        transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

        &:hover {
          background: #7093FF;
        }
      }
    }
  }
</style>
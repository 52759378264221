<template>
<!-- 分页 -->
  <div class="pagination">
    <el-pagination
      :background="background"
      :current-page.sync="currentPage" 
      :page-size.sync="pageSize" 
      :layout="layout"
      :page-sizes="pageSizes"
      :total="total"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    ></el-pagination>
  </div>
</template>

<script>
export default {
    props: {
        total: {
          required: true,
          type: Number
        },
        page: {
          type: Number,
          default: 1  //默认第一页
        },
        limit: {
          type: Number,
          default: 10  //默认每页20条
        },
        pageSizes: {
          type: Array,
          default() {
            return [10, 20, 30, 50]  //默认显示可选的每页多少条数据
          }
        },
        layout: {
          type: String,
          // default: 'total, sizes, prev, pager, next, jumper'
          default: 'prev, pager, next, jumper, total'
        },
        background: {
          type: Boolean,
          default: false
        },
        autoScroll: {
          type: Boolean,
          default: true
        },
        hidden: {
          type: Boolean,
          default: false
        }
    },
    data(){
        return {
        }
    },
    computed: {
        currentPage: {
            get() {
              return this.page
            },
            set(val) {
              this.$emit('update:page', val)  //改变的第几页的值赋值给父组件
            }
        },
        pageSize: {
            get() {
              return this.limit
            },
            set(val) {
              this.$emit('update:limit', val) //改变的当前页几条数据的值赋值给父组件
            }
        }
    },
    methods: {
        // 改变每页条数
        handleSizeChange(val) {
          this.$emit('pagination', { pageIndex: 1, pageSize: val })
        },
        // 翻页
        handleCurrentChange(val) {
          this.$emit('pagination', { pageIndex: val, pageSize: this.pageSize })
        },
    },
    mounted(){
    }
}
</script>

<style lang="less" scoped>
    .pagination {
        margin: 20px 0;
        // text-align: right;

        /deep/ .el-pagination.is-background .btn-next,
        /deep/ .el-pagination.is-background .btn-prev,
        /deep/ .el-pagination.is-background .el-pager li {
          border: 1px solid #DFDFDF;
          border-radius: 4px;
          background-color: #fff;
        }

        /deep/ .el-pagination.is-background .el-pager li:not(.disabled).active{
          background-color: #1C4B90;
        }
        /deep/ .el-pagination.is-background .el-pager li:not(.disabled):hover {
          color: #1C4B90;
        }

        /deep/ .el-pagination__total {
          margin-left: 25px;
        }
    }
</style>
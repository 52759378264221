var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "pagination" },
    [
      _c("el-pagination", {
        attrs: {
          background: _vm.background,
          "current-page": _vm.currentPage,
          "page-size": _vm.pageSize,
          layout: _vm.layout,
          "page-sizes": _vm.pageSizes,
          total: _vm.total,
        },
        on: {
          "update:currentPage": function ($event) {
            _vm.currentPage = $event
          },
          "update:current-page": function ($event) {
            _vm.currentPage = $event
          },
          "update:pageSize": function ($event) {
            _vm.pageSize = $event
          },
          "update:page-size": function ($event) {
            _vm.pageSize = $event
          },
          "size-change": _vm.handleSizeChange,
          "current-change": _vm.handleCurrentChange,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
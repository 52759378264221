var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "headers flex-align-between" }, [
    _c("div", { staticClass: "left-nav flex-align" }, [
      _vm._m(0),
      _c(
        "ul",
        { staticClass: "nav-list flex-align" },
        _vm._l(_vm.navList, function (item, index) {
          return _c("li", { key: index, staticClass: "nav csp" }, [
            _c("a", { attrs: { href: item.href } }, [
              _vm._v(_vm._s(item.name)),
            ]),
          ])
        }),
        0
      ),
    ]),
    _c(
      "div",
      { staticClass: "right-login flex-align" },
      [
        _c("el-input", {
          staticClass: "input-search",
          attrs: { placeholder: "搜索", "prefix-icon": "el-icon-search" },
          model: {
            value: _vm.search,
            callback: function ($$v) {
              _vm.search = $$v
            },
            expression: "search",
          },
        }),
        _c("div", { staticClass: "line" }),
        _c("a", { staticClass: "login csp" }, [_vm._v("登录")]),
        _c("a", { staticClass: "register csp" }, [_vm._v("免费注册")]),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "logo" }, [
      _c("h1", { staticClass: "skyviewor" }, [
        _c(
          "a",
          {
            staticClass: "logo-bd flex-align",
            attrs: { href: "/", rel: "noopener noreferrer" },
          },
          [
            _c("img", {
              attrs: {
                src: require("@/assets/images/headers/logo.png"),
                alt: "观天者",
              },
            }),
          ]
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }
import Home from './Home'


const Api = {
  Home,
  
}

// 导出
export default Api

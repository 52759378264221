var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "footer" }, [
    _c("div", { staticClass: "top-wrap flex-layout" }, [
      _c("div", { staticClass: "left-contact" }, [
        _c("img", {
          staticClass: "contact-logo",
          attrs: { src: require("@/assets/images/footer/logo.png"), alt: "" },
        }),
        _c("h3", { staticClass: "contact-us" }, [_vm._v("联系我们")]),
        _c("div", { staticClass: "contact-email csp flex-align" }, [
          _c(
            "svg",
            {
              attrs: {
                xmlns: "http://www.w3.org/2000/svg",
                width: "24",
                height: "25",
                viewBox: "0 0 24 25",
                fill: "none",
              },
            },
            [
              _c("circle", {
                attrs: { cx: "12", cy: "12.02", r: "12", fill: "#666666" },
              }),
              _c("path", {
                attrs: {
                  d: "M6.40016 7.02002H17.5998C18.3639 7.02002 18.9903 7.63032 19 8.37822L12.0024 12.2197L5.00647 8.38308C5.01295 7.63194 5.63452 7.02002 6.40016 7.02002ZM5.00647 9.85298L5 16.6456C5 17.4016 5.62967 18.02 6.40016 18.02H17.5998C18.3703 18.02 19 17.4016 19 16.6456V9.84975L12.1675 13.5116C12.0623 13.5682 11.9361 13.5682 11.8325 13.5116L5.00647 9.85298Z",
                  fill: "white",
                },
              }),
            ]
          ),
          _c("a", { staticClass: "email-p" }, [
            _vm._v("contact@skyviewor.com"),
          ]),
        ]),
        _c("div", { staticClass: "contact-wechat csp flex-align" }, [
          _c(
            "svg",
            {
              staticClass: "svg",
              attrs: {
                xmlns: "http://www.w3.org/2000/svg",
                width: "18",
                height: "18",
                viewBox: "0 0 18 18",
                fill: "none",
              },
            },
            [
              _c("path", {
                attrs: {
                  d: "M8.3443 8.16103C9.26781 7.19151 10.4383 6.85228 11.8154 6.92723C11.7847 6.78644 11.7835 6.67724 11.7382 6.59116C11.5148 6.16629 11.3392 5.69837 11.0388 5.33477C9.2416 3.15922 5.62377 2.90088 3.52483 4.78652C2.44041 5.76076 1.98877 6.97833 2.29894 8.42184C2.49754 9.34601 3.06515 10.0516 3.8038 10.6112C4.04458 10.7937 4.08358 10.9569 3.98011 11.2196C3.85172 11.5454 3.75494 11.8837 3.64453 12.2166C3.95385 12.1485 4.20381 12.045 4.434 11.9083C4.97378 11.5875 5.5096 11.413 6.15993 11.6112C6.55259 11.7307 6.99604 11.6837 7.47412 11.7152C7.12753 10.3118 7.41048 9.14147 8.3443 8.16103ZM8.75284 5.63132C9.10149 5.62632 9.31179 5.82332 9.31933 6.16179C9.3271 6.51081 9.13078 6.72426 8.79477 6.73213C8.40857 6.74122 8.11684 6.50817 8.11407 6.18844C8.11141 5.88075 8.39152 5.63643 8.75284 5.63132ZM5.44209 6.73201C5.06077 6.7333 4.77784 6.49342 4.78219 6.17252C4.78629 5.86748 5.06597 5.63412 5.43078 5.63123C5.7749 5.62858 6.01362 5.86082 6.00853 6.19344C6.00358 6.51675 5.77832 6.73097 5.44209 6.73201Z",
                  fill: "white",
                },
              }),
              _c("path", {
                attrs: {
                  d: "M14.6841 8.30805C13.2463 6.92336 10.9031 6.70062 9.19601 7.78651C6.9904 9.18948 7.00943 12.0715 9.24472 13.4244C10.3184 14.0743 11.4797 14.2563 12.7003 13.927C12.953 13.8588 13.1573 13.8785 13.3793 14.0161C13.6812 14.2032 14.0027 14.3588 14.3158 14.5279C14.3382 14.5112 14.3606 14.4945 14.383 14.4778C14.2972 14.1786 14.1932 13.8829 14.1349 13.5784C14.1129 13.464 14.1618 13.2989 14.2391 13.2098C14.4383 12.9806 14.6894 12.7964 14.888 12.5667C16.0609 11.2104 15.9775 9.55376 14.6841 8.30805ZM10.3655 9.95123C10.1042 9.95524 9.89282 9.75322 9.90103 9.50742C9.90905 9.26647 10.1001 9.09557 10.3607 9.09627C10.6474 9.09701 10.8824 9.29759 10.874 9.53435C10.8661 9.75489 10.6317 9.94715 10.3655 9.95123ZM12.9702 9.95177C12.7332 9.94819 12.5076 9.74527 12.502 9.53062C12.496 9.30086 12.7251 9.09386 12.9832 9.09582C13.2779 9.09811 13.4912 9.29067 13.4796 9.54406C13.4693 9.77026 13.2374 9.95586 12.9702 9.95177Z",
                  fill: "white",
                },
              }),
            ]
          ),
          _vm._m(0),
        ]),
      ]),
      _c("div", { staticClass: "right-nav" }, [
        _c("div", { staticClass: "nav-list-wrap" }, [
          _c("h3", { staticClass: "list-title" }, [_vm._v("帮助 | 文档")]),
          _c(
            "ul",
            { staticClass: "list" },
            _vm._l(_vm.docList, function (item, index) {
              return _c("li", { key: index, staticClass: "list-item" }, [
                _c("a", { attrs: { href: item.herf, target: item.target } }, [
                  _vm._v(_vm._s(item.name)),
                ]),
              ])
            }),
            0
          ),
        ]),
        _c("div", { staticClass: "nav-list-wrap" }, [
          _c("h3", { staticClass: "list-title" }, [_vm._v("公司")]),
          _c(
            "ul",
            { staticClass: "list" },
            _vm._l(_vm.companyList, function (item, index) {
              return _c("li", { key: index, staticClass: "list-item" }, [
                _c("a", { attrs: { href: item.herf } }, [
                  _vm._v(_vm._s(item.name)),
                ]),
              ])
            }),
            0
          ),
        ]),
        _c("div", { staticClass: "nav-list-wrap" }, [
          _c("h3", { staticClass: "list-title" }, [_vm._v("产品")]),
          _c(
            "ul",
            { staticClass: "list" },
            _vm._l(_vm.productList, function (item, index) {
              return _c("li", { key: index, staticClass: "list-item" }, [
                _c("a", { attrs: { href: item.herf } }, [
                  _vm._v(_vm._s(item.name)),
                ]),
              ])
            }),
            0
          ),
        ]),
        _c("div", { staticClass: "nav-list-wrap" }, [
          _c("h3", { staticClass: "list-title" }, [_vm._v("合作伙伴")]),
          _c(
            "ul",
            { staticClass: "list" },
            _vm._l(_vm.partnerList, function (item, index) {
              return _c("li", { key: index, staticClass: "list-item" }, [
                _c("a", { attrs: { href: item.herf } }, [
                  _vm._v(_vm._s(item.name)),
                ]),
              ])
            }),
            0
          ),
        ]),
        _c("div", { staticClass: "nav-list-wrap" }, [
          _c("h3", { staticClass: "list-title" }, [_vm._v("常见问题")]),
          _c(
            "ul",
            { staticClass: "list" },
            _vm._l(_vm.questList, function (item, index) {
              return _c("li", { key: index, staticClass: "list-item" }, [
                _c("a", { attrs: { href: item.herf } }, [
                  _vm._v(_vm._s(item.name)),
                ]),
              ])
            }),
            0
          ),
        ]),
      ]),
    ]),
    _vm._m(1),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("a", { staticClass: "wechat-p" }, [
      _vm._v("企业微信"),
      _c("div", { staticClass: "wechat-qrcode" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "bottom-wrap" }, [
      _c("span", { staticClass: "info" }, [
        _vm._v("©2021 观天者（北京）科技有限公司"),
      ]),
      _c("span", { staticClass: "info" }, [
        _c(
          "a",
          {
            staticClass: "icp",
            attrs: {
              href: "https://beian.miit.gov.cn/#/Integrated/index",
              target: "blank",
            },
          },
          [_vm._v("京ICP备2024080947号-1")]
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }
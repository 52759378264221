// 任务
const state = {
    teaPlanTerm: '登录',

   }
   
   const mutations = {
    teaPlanTerm (state, res) {
        state.teaPlanTerm = res
      },
   }
   
   export default {
     state,
     mutations
   }
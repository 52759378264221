/**
 * Created by lgzhang08@gmail.com 20161012
 * api相关的url处理类
 */
import qs from "qs"

class DoApi {

  constructor() {
  }

  /** md5加密
   * str 需要加密的字符串
   * return 加密后的字符串
   */
  getMD5(str) {
    return hex_md5(str);
  }

  /*
   * json转成url格式
   * 中文encode
   */
  jsonUrlFormat(data={}) {
    let param = function(obj) {
      let query = ''
      let name, value, fullSubName, subName, subValue, innerObj, i
      for(name in obj) {
        value = obj[name]
        if(value instanceof Array) {
          for(i = 0; i < value.length; ++i) {
            subValue = value[i]
            fullSubName = name + '[]'
            innerObj = {}
            innerObj[fullSubName] = subValue
            query += param(innerObj) + '&'
          }
        } else if(value instanceof Object) {
          for(subName in value) {
            subValue = value[subName]
            fullSubName = name + '[' + subName + ']'
            innerObj = {}
            innerObj[fullSubName] = subValue
            query += param(innerObj) + '&'
          }
        } else if(value !== undefined && value !== null) {
          query += encodeURIComponent(name) + '=' + encodeURIComponent(value) + '&'
        }
      }
      return query.length ? query.substr(0, query.length - 1) : query
    }
    // 增加 去缓存时间戳
    data.noCache = new Date().getTime()
    // 资源平台appId增加
    // if(data.apiType == 'res') data.appId = window.NewResAppId
    // 递归将json转成url, 增加sign签名 和 去缓存时间戳noCache
    return data.toString() === '[object Object]' ? param(data) : data
  }

  /**
   * 处理post请求，简单encode封装
   * 暂未使用
   */
  doJson(params) {
    return params
    // return qs.stringify(params)
  }

  /**
   * 解析cookies并获取token
   */
  getValueFromCookies(cookies, name) {
    if (!cookies) return
    let value = cookies.split(';').find(c => c.trim().startsWith(name + '='))
    if (!value) return
    // 去掉22% 引号
    value = value.replace("%22", '').replace("%22", '')
    return value.split(name + '=')[1]
  }

}

// 导出单例，方便直接使用
export default new DoApi()

// import { get, post, url} from '../service/ApiDecorator'
import {
    get,
    post,
    url
} from '../http'


class Home {
    // 查询设备总数、覆盖城市数
    @url('/public-data/statistics')
    @get
    getStatisticsData() {}
   
    // 城市分布接口
    @url('/public-data/city-distribution')
    @get
    getcityDistribution() {}

    // 视角数据查询接口
    @url('/public-data/viewports')
    @get
    getViewports() {}
}

export default new Home()
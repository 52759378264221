import axios from 'axios';
import DoApi from './utils/DoApi';
axios.defaults.timeout = 10000;
console.log(process.env.NODE_ENV)
// if (process.env.NODE_ENV == 'development') {
//   axios.defaults.baseURL = 'http://mockapi.w93nx.skyviewor.team';
//   console.log(axios.defaults.baseURL)

// } else if (process.env.NODE_ENV == 'production') {
//   axios.defaults.baseURL = './api/open';
// }
axios.defaults.baseURL = process.env.VUE_APP_API_URL

//http request 拦截器
axios.interceptors.request.use(
  config => {
    let token = Cookies.get('token')
    config.data = JSON.stringify(config.data);
    // config.headers = {
    //   'Content-Type':'application/json; charset=utf-8'
    // }
    // if(token){
    //   config.headers ['Authorization'] = 'Bearer '+token
    // }
    return config;
  },
  error => {
    return Promise.reject(err);
  }
);

//http response 拦截器
axios.interceptors.response.use(
  response => {
    // if(response.data.errCode ==2) {
    //   router.push({
    //     path:"/login",
    //     query:{redirect:router.currentRoute.fullPath}//从哪个页面跳转
    //   })
    // }
    return response.data;
  },
  error => {
    return Promise.reject(error)
  }
)
// api装饰器
function send(target, name, descriptor, callback) {
  descriptor.value = function () {
    return callback(arguments[0]);
  };
  return descriptor;
}

// 添加 路径
function url(url) {
  return function (target, name, descriptor) {
    descriptor.url = url;
  }
}

function get(target, name, descriptor) {
  return send(target, name, descriptor, function (args, url) {
    return axios.get(descriptor.url + '?' + DoApi.jsonUrlFormat(args));
  });
}

function post(target, name, descriptor) {
  return send(target, name, descriptor, function (args) {
    return axios.post(descriptor.url + '?' + DoApi.jsonUrlFormat({}), DoApi.doJson(args));
  });
}

function fetch(target, name, descriptor) {
  return send(target, name, descriptor, function (args) {
    return axios.get(descriptor.url + args);
  });
}

export {
  get,
  url,
  post,
  fetch,
}